export const projects = [
  {
    title: "North Myrtle Beach Chamber of Commerce and CVB Websites",
    subtitle: "Complete Website Ownership Transfer",
    projectdate: "December 2015",
    client: "Southern Tide Media",
    description:
      "During my tenure at Southern Tide Media, I was entrusted with the successful completion of a project previously managed by a leading agency in Myrtle Beach. The project involved modernizing an outdated Expression Engine codebase and database, which was shared with me via a Dropbox link. I leveraged my expertise in setting up a CentOS server, updating Expression Engine, and redesigning the entire site using Foundation as the CSS framework, along with design assets provided by Crawford Design, Greenville, SC. The site's initial appearance was unappealing, resembling a beachwear store. However, I successfully transformed the website into a sleek, professional platform. My engagement with the project spanned a period of five years, and I continued to offer consultation services for two additional years following the contracts completion.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682257373/explore-home-thumb.jpg",
    link: "https://www.explorenorthmyrtlebeach.com/"
  },
  {
    title: "Cartessa Aesthetics",
    subtitle: "Custom Laravel App with a WP Plugin",
    projectdate: "July 2020",
    client: "Southern Tide Media",
    description:
      "At Southern Tide Media, I undertook a project for a client requiring a custom-built 'Find a Doctor' locator. The application was designed to consume Accounts data from ZohoCRM APIs, as well as Devices data, which was cross-referenced to ensure that the Account possessed the requisite training and equipment before adding them to the map. The implementation of this system was accomplished through a custom application developed using Laravel 6.  Moreover, I also developed a Wordpress plugin to consume the API utilized on the main website and 5 microsites, each catering to one device. This plugin seamlessly integrated the 'Find a Doctor' locator with the clients existing website and provided users with a user-friendly and intuitive experience.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682277053/websites/cartessaaesthetics/cartessa-aesthetics-thumb.png",
    link: "https://www.cartessaaesthetics.com/find-a-provider"
  },
  {
    title: "Alabama Theatre",
    subtitle: "Redesign Alabama Theatre Website",
    projectdate: "December 2017",
    client: "Southern Tide Media",
    description:
      "During my tenure at Southern Tide Media, I was responsible for developing a new WordPress theme based on provided PSD designs. This involved implementing an Events calendar with functionality for recurring events and special one-time events. In addition, I incorporated a checkout system that enabled ticket purchases, sending an email with a printable ticket and a calendar invite to ensure timely attendance. The payment processing was seamlessly integrated to enhance the user experience.As part of this project, I also redesigned the homepage, which facilitated the easy creation and placement of new event posters in designated blocks. The new calendar view enhanced user experience, providing a comprehensive overview of scheduled events. My contribution to this project provided Southern Tide Media with a highly functional and visually appealing WordPress theme that met all their requirements.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682257373/alabama-theatre-thumb.jpg",
    link: "https://www.alabama-theatre.com/",
  },
  {
    title: "Owner Direct Vacation Rentals",
    subtitle: "Escapia API Listings",
    projectdate: "April 2019",
    client: "Southern Tide Media",
    description:
      "During my tenure at Southern Tide Media, I spearheaded a significant project to design and develop a PHP Escapia API class that could seamlessly retrieve, explore, and process payment transactions related to rental listings. The resulting functionality was integrated into a custom WordPress plugin, specifically tailored to align with the pre-existing website. Given Escapias decision to discontinue their plugin, I expedited the development process to ensure a prompt and efficient implementation of our custom solution, enabling continued listing display on the website. My project management skills and technical expertise were instrumental in delivering a robust and reliable solution that met the clients needs and exceeded their expectations.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682257373/odvr-thumb.jpg",
    link: "https://www.ownerdirectvacationrentals.net/",
  },
  {
    title: "Ferguson Riot Image Board",
    subtitle: "Laravel Complete Project to Document the Riots in Ferguson.",
    projectdate: "September 2018",
    client: "Freelance",
    description:
      "As a freelance developer, I successfully executed a challenging project sourced from UpWork, which entailed building a comprehensive platform using the Laravel framework. The primary aim of this platform was to enable photographers and civilians to capture images during the volatile Ferguson riots. Through meticulous planning and execution, I ensured that the platform was optimized to deliver a seamless and user-friendly experience. As a result, the top ten photographs were selected and curated into a book that was distributed to prestigious academic institutions across the nation. My efforts in this project demonstrated my ability to deliver high-quality solutions that meet complex requirements while maintaining attention to detail and adhering to strict deadlines.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/c_scale,h_615,q_auto:best/v1537159602/websites/ferguson/ferguson-upload.jpg",
    link: "",
  },
  {
    title: "The National Trader",
    subtitle: "Finish Project for Eagle Web Designs",
    projectdate: "May 2015",
    client: "Eagle Web Designs",
    description:
      "As a contracted professional for Eagle Web Designs, I successfully executed a critical project that had previously missed three launch dates due to a lack of progress by the existing staff. Upon arrival, I swiftly incorporated Payment processing into the project, thoroughly refined the codebase, and ensured that the project was delivered on the agreed-upon deadline. The client expressed their utmost satisfaction with the project's outcome, commending my exceptional performance. As a gesture of appreciation, the client bestowed upon me a bottle of Sambuka Black, my preferred alcoholic beverage at the time.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682279407/websites/tnt/tnt-listing-thumb.jpg",
    link: "",
  },
  {
    title: "Tiger Census",
    subtitle: "Tiger Census Map of All Known Tiger Populations",
    projectdate: "September 2014",
    client: "Doc Antle",
    description:
      "I developed a website utilizing CI-Bonfire, an open source project based on CodeIgniter, on which I worked as a developer for several years. The website's primary objective was to showcase a comprehensive map of the entire world, pinpointing the location of all known tigers. I had the privilege of providing extensive services to a well-known client in the period between 2012 and 2014, who may be familiar to you from the popular series, TigerKing.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682257374/tigercensus-thumb.png",
    link: "https://tigercensus.com",
  },
];

export const testimonials = [
  {
    quote:
      "I have worked with Shawn at Siteone Landscaping Supply, I found him a competent developer who was always willing to help others. I strongly recommend Shawn to anyone in need of an excellent developer to take care of projects related to web programming, web design and Azure cloud projects.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682187922/avatars/raja.jpg",
    name: "Raja Lakshmi Gudipati",
    company: "SiteOne Landscaping Supply",
  },
  {
    quote:
      "I enjoyed working with Shawn during our PIM To Hybris project. He was always personable and dedicated to getting his part of the plan done. He also diligently worked on tedious documentation and seemed to enjoy delving into the details.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682187922/avatars/bayberry.jpg",
    name: "Bayberry L Shah",
    company: "SiteOne Landscaping Supply",
  },
  {
    quote:
      "Shawn was always helpful and kind when we worked together. He was responsive, knowledgeable, and reliable.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682187922/avatars/jason.jpg",
    name: "Jason Alban",
    company: "SiteOne Landscaping Supply",
  },
  {
    quote:
      "Highly skilled and motivated, Shawn is not only a top notch developer, but he actively looks for improvements that will help clients grown even when that's 'not part of the project description', which is a rare trait. In a phrase, Shawn is a talented workaholic. ",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1676913149/avatars/brandon-evans.jpg",
    name: "Brandon Evans",
    company: "Southern Tide Media",
  },
  {
    quote:
      "I've worked closely with Shawn on a very large project with many facets, using a lot of different technologies, and he was able to absorb the big picture and understand the scope of the various challenges given to him quickly without question. He contributed some valuable pieces to our big puzzle that will definitely continue to live on and help to keep it a quality product.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1676913148/avatars/brian-clark.jpg",
    name: "Brian Clark",
    company: "Flight Circle",
  },
  {
    quote:
      "I have worked with Shawn on several projects ranging from plugin customization to complete website development. Each time Shawn kept in regular contact to provide status updates and made excellent recommendations that saved time and resources to develop. He is a gifted developer and offers more than reasonable pricing.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1676913148/avatars/nate-crocker.jpg",
    name: "Nate Crocker",
    company: "StudioP40",
  },
  {
    quote:
      "Shawn has done great work on our CVB website. He pays attention to detail and executes in a timely manner.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682187922/avatars/shafran.jpg",
    name: "Shafran Fields",
    company: "Myrtle Beach Chamber of Commerce and CVB Websites",
  },
  {
    quote:
      "Shawn is just a wonderful developer. He is ambitious and attentive which is more than important when it comes to programming. He is precise and detail oriented which goes a very long way in this industry.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1536787650/avatars/josh-profile.jpg",
    name: "Joshua Canfield",
    company: "Code Clarity TM",
  },
  {
    quote:
      "Shawn is not only an excellent high-end programmer, but he has a knack for figuring out how to do the 'impossible.' He loves the challenge and always comes up with an answer. If you have a website that needs programming changes, Shawn can do it; even when other can't.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1536787650/avatars/chuck-profile.jpg",
    name: "Chuck Bunnel",
    company: "Eagle Web Designs",
  },
  {
    quote:
      "Shawn has demonstrated an uncanny knack for detail and is able to focus squarely on great digital outcomes. My level of experience with Shawn includes devising complex solutions for stable website environments in the travel and tourism space.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1682201211/avatars/george-durant.jpg",
    name: "George DuRant",
    company: "Myrtle Beach Chamber of Commerce and CVB Websites",
  },
  {
    quote:
      "I have worked with Shawn on various projects related to online marketing and web sites for the North Myrtle Beach Chamber of Commerce. Shawn has proved himself to be a very hard working, true professional. His volume of technical knowledge on things related to online marketing, emails, web sites, social channels, programming, and web design is truly impressive. But besides knowledge, Shawn has also displayed the treats of best professionalism, holding very high standards of conduct, communication, and interaction. It is a pleasure collaborating with Shawn and see him relentlessly working on projects until every detail is taken care of and everything is finalized to the best! I am also enchanted by his writing skills, a treat not very often encountered among the ranks of IT professionals and programmers. I strongly recommend Shawn to anyone in need of an excellent professional to take care of projects related to web sites, web programming, web design, social media channels, and email marketing.",
    image: "https://res.cloudinary.com/crigger-assets/image/upload/v1536787649/avatars/antonel-profile.jpg",
    name: "Antonel Neculai",
    company: "Myrtle Beach Chamber of Commerce and CVB Websites",
  },
];

export const skills = [
  "PHP",
  "JavaScript",
  "CSS",
  "Linux Admin",
  "Full-Stack Web Development",
  "Laravel",
  "Symfony",
  "REST APIs",
  "GraphQL",
  "Material UI",
];
